/**********************************************************
 *     typeahead.js v0.11.1 - twitter bootstrap v3.3.5    *
 **********************************************************/

/*root typeahead class*/
.twitter-typeahead {
    /*display: inherit !important;*/
    width: 100%;
}

.twitter-typeahead .tt-input[disabled] {
  background-color : #eeeeee !important;
}

/*Added to input that's initialized into a typeahead*/
.twitter-typeahead .tt-input {

}

/*Added to hint input.*/
.twitter-typeahead .hint {

}

/*Added to menu element*/
.twitter-typeahead .tt-menu {
  width: 100%;
  max-height: 500px;
  overflow-y: none;
  border: 1px solid #cccccc;
  border-radius:4px;
 
  -moz-box-shadow: 12px 14px 30px -7px #616161;
  -webkit-box-shadow: 12px 14px 30px -7px #616161;
  box-shadow: 12px 14px 30px -7px #616161;
}

/*Added to dataset elements*/
.twitter-typeahead .tt-dataset {

}

/*dded to suggestion elements*/
.twitter-typeahead .tt-suggestion {
  padding: 3px 20px;
  white-space: nowrap;
  cursor: pointer;
}

/*Added to menu element when it contains no content*/
.twitter-typeahead .tt-empty {
  background-color: white;
}

/*Added to menu element when it is opened*/
.twitter-typeahead .tt-open {
  background-color: white;
}

/*Added to suggestion element when menu cursor moves to said suggestion*/
.twitter-typeahead .tt-suggestion:hover,
.twitter-typeahead .tt-suggestion:focus,
.twitter-typeahead .tt-cursor {
  cursor: hand !important;
  background-color: #337ab7;
  color: white;
}

/*Added to the element that wraps highlighted text*/
.twitter-typeahead .tt-highlight {

}

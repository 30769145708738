@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: normal;
  src: url('../fonts/Roboto-100/Roboto-100.eot');
  src: url('../fonts/Roboto-100/Roboto-100.eot?#iefix') format('embedded-opentype'),
       local('Roboto Thin'),
       local('Roboto-100'),
       url('../fonts/Roboto-100/Roboto-100.woff2') format('woff2'),
       url('../fonts/Roboto-100/Roboto-100.woff') format('woff'),
       url('../fonts/Roboto-100/Roboto-100.ttf') format('truetype'),
       url('../fonts/Roboto-100/Roboto-100.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/Roboto-regular/Roboto-regular.eot');
  src: url('../fonts/Roboto-regular/Roboto-regular.eot?#iefix') format('embedded-opentype'),
       local('Roboto'),
       local('Roboto-regular'),
       url('../fonts/Roboto-regular/Roboto-regular.woff2') format('woff2'),
       url('../fonts/Roboto-regular/Roboto-regular.woff') format('woff'),
       url('../fonts/Roboto-regular/Roboto-regular.ttf') format('truetype'),
       url('../fonts/Roboto-regular/Roboto-regular.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/Roboto-700/Roboto-700.eot');
  src: url('../fonts/Roboto-700/Roboto-700.eot?#iefix') format('embedded-opentype'),
       local('Roboto Bold'),
       local('Roboto-700'),
       url('../fonts/Roboto-700/Roboto-700.woff2') format('woff2'),
       url('../fonts/Roboto-700/Roboto-700.woff') format('woff'),
       url('../fonts/Roboto-700/Roboto-700.ttf') format('truetype'),
       url('../fonts/Roboto-700/Roboto-700.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
  src: url('../fonts/Roboto-900/Roboto-900.eot');
  src: url('../fonts/Roboto-900/Roboto-900.eot?#iefix') format('embedded-opentype'),
       local('Roboto Black'),
       local('Roboto-900'),
       url('../fonts/Roboto-900/Roboto-900.woff2') format('woff2'),
       url('../fonts/Roboto-900/Roboto-900.woff') format('woff'),
       url('../fonts/Roboto-900/Roboto-900.ttf') format('truetype'),
       url('../fonts/Roboto-900/Roboto-900.svg#Roboto') format('svg');
}
